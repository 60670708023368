import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  public myInterval: number = 5000;
  public activeSlideIndex: number = 0;
  public noWrapSlides: boolean = false;

  constructor() {}

  ngOnInit() {}

  activeSlideChange(){
    console.log(this.activeSlideIndex);
  }

  public slides: Array <Object> = [
    // {'image': '../../assets/images/heavy_flight_cr.jpg'},
    {'image': 'assets/images/home/front_01.jpg'},
    {'image': 'assets/images/home/front_02.jpg'},
    /*{'image': '../../assets/images/home/front_3.JPG'}*/
  ];

}
