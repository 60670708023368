import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-machinery',
  templateUrl: './machinery.component.html',
  styleUrls: ['./machinery.component.scss']
})
export class MachineryComponent implements OnInit {

  public machinery;

  constructor(private translate: TranslateService) {
    translate.get('machinery').subscribe((res: any) => {
      this.machinery = res;
    });
  }

  ngOnInit() {
  }


  details(type) {
    console.log(type);
  }

}
