import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  public paragraphs;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
     this.translate.get('projects.paragraphs').subscribe((res: string) => {
            this.paragraphs = res;
        });
  }

}
