import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public currentDate = {dateValue: new Date().getFullYear()};

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

}
