import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from '../home/home.component';
import {ContactComponent} from '../contact/contact.component';
import {AboutComponent} from "../about/about.component";
import {ReferencesComponent} from "../references/references.component";
import {MachineryComponent} from "../machinery/machinery.component";
import {ProjectsComponent} from "../projects/projects.component";

const routes: Routes = [
  { path: '', redirectTo: '/domov', pathMatch: 'full' },
  { path: 'domov',  component: HomeComponent},
  { path: 'podjetje',  component: AboutComponent},
  { path: 'reference',  component: ReferencesComponent},
  { path: 'strojna_oprema',  component: MachineryComponent},
  { path: 'projekti',  component: ProjectsComponent},
  { path: 'kontakt',  component: ContactComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RouterRoutingModule { }
