import {Injectable} from '@angular/core';

@Injectable()
export class SharedService {

  private language: string;

  constructor() {
    this.language = 'SI';
  }


  setLanguage(lang: string): void {
    this.language = lang;
  }

  getLanguage(): string {
    return this.language;
  }

}
