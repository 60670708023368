import {Component, Inject, OnInit} from '@angular/core';
import {SharedService} from '../services/shared.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private sharedService: SharedService,
              private translate: TranslateService) {
  }

  // public languages: string[] = ['SI', 'EN', 'RU', 'GE'];
  public languages: string[] = ['SI'];

  private defaultLanguage: string = this.languages[0];

  public currentLanguage: string;

  ngOnInit() {
    // TODO: save language choices in cookie
    // TODO: add dialog for cookie permissions
    this.sharedService.setLanguage(this.defaultLanguage);
    this.translate.addLangs(this.languages);
    this.setLanguage(this.defaultLanguage);
  }

  public changeLanguage(lang: string): void {
    const currLang = this.sharedService.getLanguage();
    this.sharedService.setLanguage(lang);
    console.log('Language ' + currLang + ' changed to ' + lang);
    this.setLanguage(lang);
  }

  private setLanguage(lang: string): void {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.currentLanguage = lang;
  }

}
