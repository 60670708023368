import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  public map: any = {
    lat: 45.881809,
    lng: 14.005900,
    zoom: 12,
    marker: {
        label: 'VDM Logar, Col 90 C, 5273 Col'
      }
    };

  constructor() { }

  ngOnInit() {
  }

}
