import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AppComponent } from './app.component';
import { CarouselComponent } from './carousel/carousel.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import {RouterRoutingModule} from './router/router-routing.module';
import { AboutComponent } from './about/about.component';
import { ReferencesComponent } from './references/references.component';
import { MachineryComponent } from './machinery/machinery.component';
import { MapComponent } from './map/map.component';
import {AgmCoreModule} from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import {SharedService} from './services/shared.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { ProjectsComponent } from './projects/projects.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    CarouselComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    ContactComponent,
    AboutComponent,
    ReferencesComponent,
    MachineryComponent,
    MapComponent,
    ProjectsComponent
  ],
  imports: [
    BrowserModule,
    MDBBootstrapModule.forRoot(),
    RouterRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBg6V6SmfpwA9lnUTVZrDJgrXBIoQvyWxQ'
    }),
    AgmSnazzyInfoWindowModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [SharedService],
  bootstrap: [AppComponent]
})
export class AppModule {}
